<template>
  <div class="menus-page">
    <div class="d-flex align-center">
      <h1>Navigation</h1>
      <v-spacer></v-spacer>
      <v-btn text outlined class="mr-2" @click="$router.push({ name: 'website.redirects' })">URL redirects</v-btn>
      <v-btn color="primary" @click="$router.push({ name: 'website.menus.create' })" depressed>
        <v-icon left>mdi-plus</v-icon> Add menu</v-btn
      >
    </div>
    <SkeletonLoaders v-if="isLoading" type="table" />

    <v-card v-else class="py-0 pl-4">
      <v-row>
        <v-col cols="12" sm="6">
          <Notification type="error" :messages="errors" v-if="errors.length > 0" />
          <h2>Menus</h2>
          <p>Menus, or link lists, help your customers navigate around your online store.</p>
          <p>You can also create nested menus to display drop-down menus, and group products or pages together.</p>
        </v-col>
        <v-col cols="12" sm="6">
          <template>
            <v-simple-table cols="tbl-menus">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Title
                    </th>
                    <th class="text-left">
                      Menu items
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(menu, key) in menus" :key="key">
                    <td>
                      <router-link class="rt-link" :to="{ name: 'website.menus.update', params: { id: menu.id } }">
                        {{ menu.title }}
                      </router-link>
                    </td>
                    <td>{{ listTitleMenuItems(menu.items) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-col>
      </v-row></v-card
    >
  </div>
</template>

<script>
import { menuService } from '@/apis/menu.s';
import Notification from '@/components/Notification';
import SkeletonLoaders from '@/components/SkeletonLoaders';
import { apiThemes } from '@/apis/theme.js';
import event from '@/plugins/event-bus';

export default {
  components: {
    Notification,
    SkeletonLoaders,
  },
  data() {
    return {
      menus: [
        {
          created_at: '2021-01-04T02:49:29+00:00',
          handle: 'main-menu',
          id: 322404,
          is_default: true,
          shop_id: 10129992,
          title: 'Main menu',
          updated_at: '2021-01-04T02:49:29+00:00',
          items: [
            {
              children: [],
              id: 1535661,
              menu_id: 322404,
              name: 'Man',
              parent_id: null,
              position: 0,
              type: 'collections',
            },
            {
              children: [],
              id: 1535662,
              menu_id: 322404,
              name: 'Women',
              parent_id: null,
              position: 1,
              type: 'collections',
            },
            {
              children: [],
              id: 1535663,
              menu_id: 322404,
              name: 'GPS Watches',
              parent_id: null,
              position: 2,
              type: 'collections',
            },
            {
              children: [],
              id: 1535664,
              menu_id: 322404,
              name: 'Accessories',
              parent_id: null,
              position: 3,
              type: 'collections',
            },
          ],
        },
      ],
      isLoading: false,
      errors: [],
    };
  },
  created() {
    this.getMenus();
  },

  methods: {
    async getMenus() {
      this.isLoading = true;
      try {
        let menuData = await menuService.list();
        this.menus = menuData.data;
        console.log(this.menus);
        this.getTheme();
        event.$emit('getStepStatus', message => {
          this.getStep();
        });
        this.isLoading = false;
      } catch (error) {
        this.errors = error.response.data.message || [];
        this.isLoading = false;
      }
    },
    listTitleMenuItems(items) {
      let arr = items.map(item => item.name);
      return arr.join(',') || '';
    },
    checkMenu(key) {
      let item = this.menus.find(it => it.handle === key);
      if (item) return item.id;
      else return null;
    },
    async getTheme() {
      this.isLoading = true;
      try {
        let themeDefault = await apiThemes.get();
        if (themeDefault.data[0]) {
          let res = await apiThemes.getConfig(themeDefault.data[0].id);
          if (res) {
            let convertData = { ...res.data[0] };

            if (
              !convertData.pages.fixed.footer.block[1].config[0].value &&
              !convertData.pages.fixed.header.block[2].config[0].value
            ) {
              convertData.pages.fixed.footer.block[1].config[0].value = this.checkMenu('footer-menu');
              convertData.pages.fixed.header.block[2].config[0].value = this.checkMenu('default-menu');
              apiThemes.updateConfig(themeDefault.data[0].id, convertData.id, convertData);
            }
          }
        }
      } catch (error) {
        //empty
      }
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss">
.menus-page {
  .v-data-table {
    background-color: transparent;
  }
}
.rt-link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
</style>
